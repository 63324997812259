export default [
  {
    img1: "pl-L-120001.png",
    img2: "pl-00007513a.png",
    link: "https://sklep.tayama.pl/oferta-handlowa,39?search=120001",
    opis:
      "<h3>Model: L-120001</h3>Moc znamionowa: 40 W; Źródło światła: 1 x matryca LED; Żywotność (L70B50, t?=25°C): 30000 h; Strumień świetlny: 3000 lm; Temperatura barwowa: 4000 K ±10%; Barwa światła: neutralny biały; Kąt świecenia: 55°; Współczynnik mocy: >0,9; Ogólny wskaźnik oddawania barw: Ra ≥70; Wydajność świetlna: 75 lm/W; Klasa efektywności energetycznej: A; Czas startu: ≤0,5 s; Znamionowe napięcie zasilania: AC 200 - 240 V; Częstotliwość napięcia zasilania: 50 - 60 Hz; Zużycie energii: 40 kWh/1000 h; Klasa ochronności: I; Stopień ochrony: IP65; Obudowa: stop aluminum; Klosz: szklany; Kolor: grafitowy; Temperatura pracy: -40 ... +50°C; Wilgotność pracy (bez kondensacji): RH ≤90%; Temperatura przechowywania: -40 ... +50°C; Wymiary: O455 x 627 mm; Waga (netto): 9,95 kg; Sposób montażu: na słupie O89 mm.",
  },
  {
    img1: "pl-L-120002.png",
    img2: "pl-00007515a.png",
    link: "https://sklep.tayama.pl/oferta-handlowa,39?search=120002",
    opis:
      "<h3>Model: L-120002</h3>Moc znamionowa: 40 W; Źródło światła: 1 x matryca LED; Żywotność (L70B50, t?=25°C): 30000 h; Strumień świetlny: 2600 lm; Temperatura barwowa: 4000 K ±10%; Barwa światła: neutralny biały; Kąt świecenia: 116°; Współczynnik mocy: >0,9; Ogólny wskaźnik oddawania barw: Ra ≥70; Wydajność świetlna: 65 lm/w; Klasa efektywności energetycznej: A; Czas startu: ≤0,5 s; Znamionowe napięcie zasilania: AC 200 - 240 V; Częstotliwość napięcia zasilania: 50 - 60 Hz; Zużycie energii: 40 kWh/1000 h; Klasa ochronności: I; Stopień ochrony: IP65; Obudowa: stop aluminum; Klosz: poliwęglan; Kolor: grafitowy; Temperatura pracy: -40 ... +50°C; Wilgotność pracy (bez kondensacji): RH ≤90%; Temperatura przechowywania: -40 ... +50°C; Wymiary: O397 x 576 mm; Waga (netto): 6,15 kg; Sposób montażu: na słupie O75 mm; Informacje dodatkowe: kopuła podświetlana na niebiesko.",
  },
  {
    img1: "pl-L-120003.png",
    img2: "pl-00007511a.png",
    link: "https://sklep.tayama.pl/oferta-handlowa,39?search=120003",
    opis:
      "<h3>Model: L-120003</h3>Moc znamionowa: 35 W; Źródło światła: 15 x Cree Power LED (diod mocy); Żywotność (L70B50, t?=25°C): 30000 h; Strumień świetlny: 2450 lm; Temperatura barwowa: 4000 K ±10%; Barwa światła: neutralny biały; Kąt świecenia: 107°; Współczynnik mocy: >0,9; Ogólny wskaźnik oddawania barw: Ra ≥70; Wydajność świetlna: 70 lm/w; Klasa efektywności energetycznej: A; Czas startu: ≤0,5 s; Znamionowe napięcie zasilania: AC 200 - 240 V; Częstotliwość napięcia zasilania: 50 - 60 Hz; Zużycie energii: 35 kWh/1000 h; Klasa ochronności: I; Stopień ochrony: IP65; Obudowa: stop aluminum; Kolor: grafitowy; Temperatura pracy: -40 ... +50°C; Wilgotność pracy (bez kondensacji): RH ≤90%; Temperatura przechowywania: -40 ... +50°C; Wymiary: O550 x 98 mm; Waga (netto): 5,87 kg; Sposób montażu: na słupie O75 mm (uchwyt przelotowy); Informacje dodatkowe: podświetlane białe elementy ozdobne wewnątrz i na zewnątrz lampy.",
  },
  {
    img1: "pl-L-120004.png",
    img2: "pl-00007517a.png",
    link: "https://sklep.tayama.pl/oferta-handlowa,39?search=120004",
    opis:
      "<h3>Model: L-120004</h3>Moc znamionowa: 30 W; Źródło światła: 1 x matryca LED; Żywotność (L70B50, t?=25°C): 30000 h; Strumień świetlny: 2550 lm; Temperatura barwowa: 4000 K ±10%; Barwa światła: neutralny biały; Kąt świecenia: 130°; Współczynnik mocy: >0,9; Ogólny wskaźnik oddawania barw: Ra ≥70; Wydajność świetlna: 85 lm/w; Klasa efektywności energetycznej: A; Czas startu: ≤0,5 s; Znamionowe napięcie zasilania: AC 200 - 240 V; Częstotliwość napięcia zasilania: 50 - 60 Hz; Zużycie energii: 30 kWh/1000 h; Klasa ochronności: I; Stopień ochrony: IP65; Obudowa: stop aluminum; Klosz: poliwęglan; Kolor: grafitowy; Temperatura pracy: -40 ... +50°C; Wilgotność pracy (bez kondensacji): RH ≤90%; Temperatura przechowywania: -40 ... +50°C; Wymiary: O680 x 648 mm; Waga (netto): 7,1 kg; Sposób montażu: na słupie O89 mm.",
  },
]
