import React from "react"
import styled from "styled-components"

const Wrapper = styled.header`
  text-align: center;
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  z-index: 999;
  top: 0;
  left: 0;
  display: block;
  background-color: ${props => props.theme.tayamaSzary};
  color: white;
  border-bottom: 1px solid white;
  a {
    color: white;
  }
`
const Header = () => {
  return (
    <Wrapper>
      <a href='https://sklep.tayama.pl/oferta-handlowa/oswietlenie-led/oprawy-uliczne-i-parkowe/oprawy-parkowe-parkline,39,11587'>
        <h1>Lampy parkowe PARKLINE</h1>
      </a>
    </Wrapper>
  )
}

export default Header
