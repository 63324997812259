import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Wrapper = styled.div`
  margin: 15px 15px 10px 15px;
  border: 1px solid white;
  border-radius: 20%;
  box-shadow: 6px 6px 6px 0px rgba(0, 0, 0, 0.6);
`

const Obrazek = (alt, fluid, styl, link) => {
  return (
    <div className={styl}>
      <Wrapper>
        <a href={link}>
          <Img fluid={fluid} alt={alt} />
        </a>
      </Wrapper>
    </div>
  )
}

export default Obrazek
