import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Wrapper = styled.div`
  margin: 15px 5px 10px 5px;
  border: 1px solid white;
  border-radius: 5px;
  box-shadow: 6px 6px 6px 0px rgba(0, 0, 0, 0.6);
  background-color: #384a5680;
  padding: 1.4rem;
  color: white;
`

const Opis = (alt, fluid, styl, opis) => {
  return (
    <div className={styl}>
      <Wrapper className='grid-x align-top'>
        <div className='cell small-1'>
          <Img fluid={fluid} alt={alt} />
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: `<div> ${opis} </div>` }}
          className='cell small-11'
        ></div>
      </Wrapper>
    </div>
  )
}

export default Opis
