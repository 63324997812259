import React from "react"
import lampy from "../constants/lampy"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import Obrazek from "./Obrazek"
import Opis from "./Opis"

const TrescPark = () => {
  const data = useStaticQuery(graphql`
    query MyQueryPark {
      allFile(filter: { relativePath: { regex: "/^pl-/i" } }) {
        nodes {
          relativePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      file(relativePath: { eq: "park-zima2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      fluid={data.file.childImageSharp.fluid}
      className='szare-tlo'
    >
      <div className='grid-container fluid'>
        {lampy.map((item, index) => {
          var zgoda1 = data.allFile.nodes.filter(value => {
            return value.relativePath === item.img1
          })
          var zgoda2 = data.allFile.nodes.filter(value => {
            return value.relativePath === item.img2
          })
          return (
            <div className='grid-x align-top' key={index}>
              {Obrazek(
                item.img1,
                zgoda1[0].childImageSharp.fluid,
                "cell small-12 small-order-1 large-4 " +
                  (index % 2 === 0 ? "large-order-1" : "large-order-2"),
                item.link
              )}
              {Opis(
                item.img2,
                zgoda2[0].childImageSharp.fluid,
                "cell small-12 small-order-2 large-8 " +
                  (index % 2 === 0 ? "large-order-2" : "large-order-1"),
                item.opis
              )}
            </div>
          )
        })}
      </div>
    </BackgroundImage>
  )
}

const ParkLine = styled(TrescPark)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y repeat-x;
`

export default ParkLine
